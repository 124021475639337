// @ts-strict-ignore
import React from "react"

import moment, { Moment } from "moment"
import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css"
import styled from "styled-components"

import Alert from "@kiwicom/orbit-components/lib/Alert"
import Button from "@kiwicom/orbit-components/lib/Button"
import InputField from "@kiwicom/orbit-components/lib/InputField"
import ListChoice from "@kiwicom/orbit-components/lib/ListChoice"
import Loading from "@kiwicom/orbit-components/lib/Loading"
import Radio from "@kiwicom/orbit-components/lib/Radio"
import Select from "@kiwicom/orbit-components/lib/Select"
import Separator from "@kiwicom/orbit-components/lib/Separator"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import Tile from "@kiwicom/orbit-components/lib/Tile"
import PowerPlug from "@kiwicom/orbit-components/lib/icons/PowerPlug"

import CardList from "components/card-list"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"

import { fetchFromAPIBase } from "utils/fetch-utils"

import { ChargeMetric } from "./charge-reservation"
import { ChargePoint, VehicleChargingPort } from "./interfaces"
import { chargingPortNameWithPosition, linkToMap } from "./utils"

const StyledDateTimeSelector = styled.div`
  display: flex;
  min-height: ${(props) => props.theme.orbit.heightButtonNormal};
  border-radius: ${(props) =>
    props.theme.orbit.borderRadiusNormal +
    " " +
    props.theme.orbit.borderRadiusNormal +
    " 0 0"};

  .datetime {
    background: ${(props) => props.theme.orbit.backgroundButtonWhite};
    color: ${(props) => props.theme.orbit.colorTextButtonWhite};
    border: ${(props) => "1px solid " + props.theme.orbit.borderColorTable};
    padding: 5px;
    display: block;
    margin: auto;
    text-align: center;
  }
`

interface CreateReservationProps {
  goBack?: () => void
}

interface CreateReservationState {
  startDate: Moment
  endDate: Moment
  maxPower: number
  connectorStandard: "CCS2" | "CHaDeMo"
  vehicleChargingPorts?: Map<VehicleChargingPort, boolean>
  chargePoints?: ChargePoint[]
  selectedChargePoint?: ChargePoint
  errorMessage?: string
}

export class CreateReservation extends React.Component<
  CreateReservationProps,
  CreateReservationState
> {
  constructor(props: CreateReservationProps) {
    super(props)
    this.state = {
      startDate: moment(),
      endDate: moment().add(2, "hours"),
      maxPower: 150,
      connectorStandard: "CCS2",
      vehicleChargingPorts: null,
      chargePoints: null,
      selectedChargePoint: null,
      errorMessage: null,
    }
    this.setStart = this.setStart.bind(this)
    this.setEnd = this.setEnd.bind(this)
    this.setMaxPower = this.setMaxPower.bind(this)
    this.setConnectorStandard = this.setConnectorStandard.bind(this)
    this.createReservation = this.createReservation.bind(this)
  }

  setStart(date: Moment) {
    this.setState({ startDate: date })
    this.getChargePoints()
  }

  setEnd(date: Moment) {
    this.setState({ endDate: date })
    this.getChargePoints()
  }

  setMaxPower(event) {
    this.setState({
      maxPower: event.target.value,
    })
  }

  setConnectorStandard(event) {
    this.setState({
      connectorStandard: event.target.value,
    })
  }

  componentDidMount() {
    this.getVehicleChargingPorts()
    this.getChargePoints()
  }

  getVehicleChargingPorts() {
    fetchFromAPIBase({
      path: "/v1/charging/vehicle_charging_ports/list/",
    }).subscribe((response) => {
      if (response && !response.error) {
        const portIsSelected = new Map()
        response.map((vehicleChargingPort: VehicleChargingPort) => {
          portIsSelected.set(vehicleChargingPort, false)
        })
        this.setState({ vehicleChargingPorts: portIsSelected })
      }
    })
  }

  selectedVehicleChargingPorts(): Array<VehicleChargingPort> {
    const vehicleChargingPorts: Array<VehicleChargingPort> = []
    if (!this.state.vehicleChargingPorts) {
      // We haven't loaded vehicleChargingPorts yet
      return vehicleChargingPorts
    }

    for (const [
      vehicleChargingPort,
      isSelected,
    ] of this.state.vehicleChargingPorts.entries()) {
      if (isSelected) {
        vehicleChargingPorts.push(vehicleChargingPort)
      }
    }
    return vehicleChargingPorts
  }

  createVehicleChargingPortToggle(vehicleChargingPort: VehicleChargingPort) {
    return () => {
      this.setState((previousState) => {
        previousState.vehicleChargingPorts.set(
          vehicleChargingPort,
          !previousState.vehicleChargingPorts.get(vehicleChargingPort)
        )
        this.setState(previousState)
      })
    }
  }

  generateVehicleChargingPortChoices() {
    const choices = []
    for (const vehicleChargingPort of this.state.vehicleChargingPorts.keys()) {
      choices.push(
        <ListChoice
          key={vehicleChargingPort.id}
          title={chargingPortNameWithPosition(vehicleChargingPort)}
          description={`${vehicleChargingPort.licensePlate} - ${vehicleChargingPort.batteryCapacity}kWh Battery - ${vehicleChargingPort.supportedConnectors}`}
          selectable={true}
          onClick={this.createVehicleChargingPortToggle(vehicleChargingPort)}
          selected={this.state.vehicleChargingPorts.get(vehicleChargingPort)}
        />
      )
    }
    return choices
  }

  generateChargePointChoices() {
    const choices = []
    for (const chargePoint of this.state.chargePoints) {
      choices.push(
        <Tile
          onClick={() => this.setState({ selectedChargePoint: chargePoint })}
        >
          <Stack direction="column">
            <Stack direction="row">
              <Stack direction="column" spacing="small" justify="center">
                <Stack direction="row">
                  <Text weight="bold">{chargePoint.name}</Text>
                  <Text>
                    {linkToMap(
                      chargePoint.location.latitude,
                      chargePoint.location.longitude
                    )}
                  </Text>
                  <Radio
                    onChange={() =>
                      this.setState({ selectedChargePoint: chargePoint })
                    }
                    checked={chargePoint === this.state.selectedChargePoint}
                  />
                </Stack>
                <Text>
                  {chargePoint.maximumPower}kW {chargePoint.model.name}
                </Text>
              </Stack>
            </Stack>
            <Separator />
            {chargePoint.availability.map((availability) => {
              return (
                <Stack direction="row">
                  <Text>
                    <ChargeMetric icon={<PowerPlug color="success" />}>
                      {availability.availablePower}kW available
                    </ChargeMetric>
                  </Text>
                </Stack>
              )
            })}
          </Stack>
        </Tile>
      )
    }
    return choices
  }

  getChargePoints() {
    fetchFromAPIBase({
      path: `/v1/charging/charge_points/?include_availability=true&start_of_availability_window=${this.state.startDate.toISOString()}&end_of_availability_window=${this.state.endDate.toISOString()}`,
      method: "GET",
    }).subscribe((response) => {
      if (response && !response.error) {
        this.setState({ chargePoints: response })
      }
    })
  }

  createReservation() {
    fetchFromAPIBase({
      path: "/v1/charging/reservations/",
      method: "POST",
      body: {
        start: this.state.startDate.toISOString(),
        end: this.state.endDate.toISOString(),
        vehicle_charging_port_ids: this.selectedVehicleChargingPorts().map(
          (vehicleChargingPort: VehicleChargingPort) => {
            return vehicleChargingPort.id
          }
        ),
        reservations: [
          {
            max_power: this.state.maxPower,
            charge_point_id: this.state.selectedChargePoint.id,
            connector_type: this.state.connectorStandard,
            min_amperes: 0,
          },
        ],
      },
    }).subscribe((response) => {
      if (response && !response.error) {
        this.props.goBack()
      } else {
        switch (response.error.status) {
          case 401: {
            switch (response.error.response) {
              case "NoConnectorAvailable": {
                this.setState({
                  errorMessage:
                    "All cables are booked at this charger. Please choose a different time",
                })
                break
              }
              case "NoParkingBayAvailable": {
                this.setState({
                  errorMessage:
                    "All parking bays are booked at this charger. Please choose a different time",
                })
                break
              }
              case "AvailableConnectorsDontHaveParkingBay": {
                this.setState({
                  errorMessage:
                    "All parking bays are booked at this charger. Please choose a different time.",
                })
                break
              }
              case "NoPowerAvailable": {
                this.setState({
                  errorMessage:
                    "All of the available power is booked at this charger. Please choose a different time.",
                })
                break
              }
              default: {
                this.setState({
                  errorMessage:
                    "We couldn't create your reservation for an unknown reason. Please contact us.",
                })
                break
              }
            }
            break
          }
          case 400: {
            if (response.error.response.error == "ReservationValidationError") {
              this.setState({
                errorMessage: response.error.response.details.toString(),
              })
            } else {
              this.setState({
                errorMessage:
                  "Oops, your reservation is invalid, please check your input data.",
              })
            }
            break
          }
          default: {
            this.setState({
              errorMessage: "An unexpected error occurred. Please contact us.",
            })
            break
          }
        }
      }
    })
  }

  buildSubmitButton(): JSX.Element {
    const isDisabled =
      this.selectedVehicleChargingPorts().length === 0 ||
      !this.state.selectedChargePoint ||
      !this.state.startDate ||
      !this.state.endDate ||
      !this.state.connectorStandard
    return (
      <Stack direction="column">
        <Button disabled={isDisabled} onClick={this.createReservation}>
          Reserve
        </Button>
        {this.state.errorMessage && (
          <Alert type="critical">{this.state.errorMessage}</Alert>
        )}
      </Stack>
    )
  }

  render() {
    return (
      <Stack direction="column" spacing="large">
        <Tile>
          <Stack direction="row" align="center" spacing="small" inline shrink>
            <Stack direction="column" spacing="small">
              <Text>Start</Text>
              <StyledDateTimeSelector>
                <Datetime
                  inputProps={{ className: "datetime" }}
                  value={this.state.startDate}
                  onChange={this.setStart}
                  dateFormat="Do MMMM YYYY"
                />
              </StyledDateTimeSelector>
            </Stack>
            <Stack direction="column" spacing="small">
              <Text size="large">End</Text>
              <StyledDateTimeSelector>
                <Datetime
                  inputProps={{ className: "datetime" }}
                  value={this.state.endDate}
                  onChange={this.setEnd}
                  dateFormat="Do MMMM YYYY"
                />
              </StyledDateTimeSelector>
            </Stack>
          </Stack>
        </Tile>
        <Tile>
          <Stack direction="row" align="center" spacing="large" inline shrink>
            <InputField
              value={this.state.maxPower.toString()}
              minValue={0}
              placeholder="Power needed"
              onChange={this.setMaxPower}
              label="Maximum Power (kW)"
            />

            <Select
              options={[
                { value: "CCS2", label: "CCS 2" },
                { value: "ChaDeMo", label: "ChaDeMo" },
              ]}
              onChange={this.setConnectorStandard}
              value={this.state.connectorStandard}
              label="Cable Standard"
            />
          </Stack>
        </Tile>
        <CardList title="Vehicles">
          {this.state.vehicleChargingPorts ? (
            this.generateVehicleChargingPortChoices()
          ) : (
            <Loading />
          )}
        </CardList>

        <CardList title="Availability">
          {this.state.chargePoints && this.generateChargePointChoices()}
        </CardList>

        <EmberCard>
          <EmberCardSection>
            Reserving a {this.state.connectorStandard} Cable for{" "}
            {moment
              .duration(this.state.endDate.diff(this.state.startDate))
              .humanize()}
            {this.state.vehicleChargingPorts &&
              this.selectedVehicleChargingPorts().length !== 0 &&
              ` for ${this.selectedVehicleChargingPorts()
                .map(chargingPortNameWithPosition)
                .join(", ")}`}
            {this.state.selectedChargePoint &&
              ` at ${this.state.selectedChargePoint?.name}`}
            {` with up to ${this.state.maxPower}kW`}
          </EmberCardSection>
        </EmberCard>

        {this.buildSubmitButton()}
      </Stack>
    )
  }
}
