// @ts-strict-ignore
import React from "react"

import { Button, Heading, Stack } from "@kiwicom/orbit-components"
import { ChevronBackward, Plus } from "@kiwicom/orbit-components/icons"

import { CreateReservation } from "components/charging/create-reservation"
import { Reservations } from "components/charging/reservations"
import Container from "components/container"
import { ChargeLayout } from "components/layout-custom"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ChargeProps {}

interface ChargeState {
  mode: "list" | "create"
}

export default class Page extends React.Component<ChargeProps, ChargeState> {
  constructor(props: ChargeProps) {
    super(props)
    this.state = {
      mode: "list",
    }

    this.createMode = this.createMode.bind(this)
    this.listMode = this.listMode.bind(this)
  }

  createMode() {
    this.setState({ mode: "create" })
  }

  listMode() {
    this.setState({ mode: "list" })
  }

  render() {
    let content = null

    if (this.state.mode === "list") {
      content = (
        <Stack spacing="XLarge">
          <Button
            iconLeft={<Plus size="small" />}
            type="primary"
            size="small"
            onClick={this.createMode}
          >
            New Reservation
          </Button>
          <Reservations display={["current", "upcoming"]} />
        </Stack>
      )
    } else {
      content = (
        <Stack spacing="XLarge">
          <Button
            iconLeft={<ChevronBackward />}
            type="primary"
            size="small"
            onClick={this.listMode}
          >
            Go back
          </Button>
          <Heading>New Reservation</Heading>
          <CreateReservation goBack={this.listMode} />
        </Stack>
      )
    }

    return (
      <ChargeLayout title="Charge">
        <Container>{content}</Container>
      </ChargeLayout>
    )
  }
}
